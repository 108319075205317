import {
	type UserInvite,
	type UserInviteWithRelations,
	type Domain,
	type DomainWithMetrics,
	type FieldNotFoundErrorItem,
	type FieldNotFoundErrorResponse,
	type HTTPValidationError,
	type Organization,
	type OrganizationWithMetrics,
	type RegistrarAccount,
	type RegistrarAccountWithMetrics,
	type User,
	type UserWithMetrics,
	type ValidationError,
	type ValueErrorResponse,
	type Job,
	type JobWithMetrics,
	ClientCandidateState,
	InternalCandidateState,
	type CandidateMatch,
	type CandidateMatchEnriched,
	type JobWithWeeklyQuota,
} from './api-client.types.gen'

export function isValueErrorResponse(
	value: unknown,
): value is ValueErrorResponse {
	return (
		typeof value === 'object' &&
		value !== null &&
		'details' in value &&
		typeof (value as ValueErrorResponse).details === 'string'
	)
}

export function isFieldNotFoundErrorResponse(
	value: unknown,
): value is FieldNotFoundErrorResponse {
	if (typeof value !== 'object' || value === null || !('errors' in value)) {
		return false
	}

	const { errors } = value as { errors: unknown }

	if (!Array.isArray(errors)) {
		return false
	}

	return errors.every(
		(item) =>
			typeof item === 'object' &&
			item !== null &&
			'entity_name' in item &&
			'entity_id' in item &&
			'field' in item &&
			typeof (item as FieldNotFoundErrorItem).entity_name === 'string' &&
			typeof (item as FieldNotFoundErrorItem).entity_id === 'string' &&
			typeof (item as FieldNotFoundErrorItem).field === 'string',
	)
}

function isValidationError(value: unknown): value is ValidationError {
	return (
		typeof value === 'object' &&
		value !== null &&
		'loc' in value &&
		Array.isArray((value as ValidationError).loc) &&
		(value as ValidationError).loc.every(
			(item) => typeof item === 'string' || typeof item === 'number',
		) &&
		'msg' in value &&
		typeof (value as ValidationError).msg === 'string' &&
		'type' in value &&
		typeof (value as ValidationError).type === 'string'
	)
}

export function isHTTPValidationError(
	value: unknown,
): value is HTTPValidationError {
	if (typeof value !== 'object' || value === null) {
		return false
	}

	if ('detail' in value) {
		const detail = (value as HTTPValidationError).detail
		return (
			detail === undefined ||
			(Array.isArray(detail) && detail.every(isValidationError))
		)
	}

	return true // HTTPValidationError can have an empty object
}

export function isUserWithMetrics(
	user: User | UserWithMetrics,
): user is UserWithMetrics {
	return 'job_count' in user
}

export function isOrganizationWithMetrics(
	org: Organization | OrganizationWithMetrics,
): org is OrganizationWithMetrics {
	return 'job_count' in org
}

export function isRegistrarAccountWithMetrics(
	registrarAccount: RegistrarAccount | RegistrarAccountWithMetrics,
): registrarAccount is RegistrarAccountWithMetrics {
	return 'domain_count' in registrarAccount
}

export function isDomainWithMetrics(
	domain: Domain | DomainWithMetrics,
): domain is DomainWithMetrics {
	return 'dns_records_count' in domain
}

export function isUserInviteWithRelations(
	invite: UserInvite | UserInviteWithRelations,
): invite is UserInviteWithRelations {
	return 'creator' in invite || 'organization' in invite
}

export function isJobWithMetrics(
	job: Job | JobWithMetrics,
): job is JobWithMetrics {
	return 'candidates_needs_review' in job
}

export function isJobWithWeeklyQuota(
	job: Job | JobWithWeeklyQuota,
): job is JobWithWeeklyQuota {
	return 'candidates_outreached_weekly' in job
}

export const isClientCandidateState = (
	status: ClientCandidateState | InternalCandidateState,
): status is ClientCandidateState =>
	Object.values(ClientCandidateState).includes(status as ClientCandidateState)

export const isInternalCandidateState = (
	status: ClientCandidateState | InternalCandidateState,
): status is InternalCandidateState =>
	Object.values(InternalCandidateState).includes(
		status as InternalCandidateState,
	)

// Type guard to check if object is CandidateMatch or CandidateMatchEnriched
export function isCandidateMatchType(
	obj: any,
): obj is CandidateMatch | CandidateMatchEnriched {
	// Check required properties
	if (
		typeof obj !== 'object' ||
		obj === null ||
		typeof obj.id !== 'string' ||
		typeof obj.created_at !== 'string' ||
		typeof obj.updated_at !== 'string' ||
		typeof obj.job_id !== 'string' ||
		typeof obj.candidate_id !== 'string' ||
		typeof obj.latest_user_id !== 'string' ||
		typeof obj.latest_search_id !== 'string'
	) {
		return false
	}

	// Check state property
	if (typeof obj.state !== 'string') {
		return false
	}

	// Check nullable string properties
	const nullableStringProps = [
		'latest_sequence_id',
		'message_id',
		'reviewer_id',
		'human_review_notes',
		'client_id',
		'error_message',
		'client_state',
		'internal_state',
	]

	for (const prop of nullableStringProps) {
		if (
			obj[prop] !== undefined &&
			obj[prop] !== null &&
			typeof obj[prop] !== 'string'
		) {
			return false
		}
	}

	// Check nullable number properties
	const nullableNumberProps = [
		'view_count',
		'reminder_count',
		'human_review_duration_seconds',
		'client_review_duration_seconds',
		'total_duration_seconds',
	]

	for (const prop of nullableNumberProps) {
		if (
			obj[prop] !== undefined &&
			obj[prop] !== null &&
			typeof obj[prop] !== 'number'
		) {
			return false
		}
	}

	// Check nullable object properties
	const nullableObjectProps = [
		'sentiment_result',
		'review_action',
		'human_review_action',
		'client_decision',
		'introduction_email',
		'decline_email',
		'reject_email',
		'job',
		'organization',
		'latest_sequence',
	]

	for (const prop of nullableObjectProps) {
		if (
			obj[prop] !== undefined &&
			obj[prop] !== null &&
			typeof obj[prop] !== 'object'
		) {
			return false
		}
	}

	return true
}

// Helper function to specifically identify CandidateMatchEnriched
export function isCandidateMatchEnriched(
	obj: CandidateMatch | CandidateMatchEnriched,
): obj is CandidateMatchEnriched {
	return (
		'candidate' in obj &&
		obj.candidate !== undefined &&
		obj.candidate !== null
	)
}

// Helper function to specifically identify CandidateMatch
export function isCandidateMatch(
	obj: CandidateMatch | CandidateMatchEnriched,
): obj is CandidateMatch {
	return !isCandidateMatchEnriched(obj)
}
